import React, { useState } from 'react';
import axios from 'axios';
import './TextToSpeech.css';


const TextToSpeech = () => {
  const [textToSpeechInput, setTextToSpeechInput] = useState({
    englishText: '',
    isMaleVoice: false
  });
  const [audioUrl, setAudioUrl] = useState('');

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === 'checkbox' ? checked : value;
    setTextToSpeechInput({ ...textToSpeechInput, [name]: inputValue });
  };

  const convertToSpeech = async () => {
    try {
      const response = await axios.post('https://michaltexttospeech.azurewebsites.net/texttospeech', textToSpeechInput);
      setAudioUrl(`https://michaltexttospeech.azurewebsites.net/texttospeech?fileId=${response.data.fileId}`);
    } catch (error) {
      console.error('Error converting text to speech:', error);
    }
  };

  const downloadAudio = async () => {
    try {
      const response = await axios.get(audioUrl, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'speech.mp3');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading audio:', error);
    }
  };

  return (
    <div className='text-to-speech-container'>
      <h1 className='header_title'>Text2Speech Converter</h1>
      <label>
        <input
          type="text"
          name="englishText"
          value={textToSpeechInput.englishText}
          onChange={handleInputChange}
          placeholder='Insert your text here'
          className='input_field'
        />
      </label>
      <br />
      <br />
      <label>
        Male Voice:
        <input
          type="checkbox"
          name="isMaleVoice"
          checked={textToSpeechInput.isMaleVoice}
          onChange={handleInputChange}
          className='checkbox_input'
        />
      </label>
      <br />
      <br />
      <button className='convert_button' onClick={convertToSpeech}>Convert to Speech</button>
      {audioUrl && <button className='download_button' onClick={downloadAudio}>Download MP3</button>}
      <br />
      {audioUrl && <audio className='audio_controlls' controls src={audioUrl} />}
    </div>
  );
};

export default TextToSpeech;
